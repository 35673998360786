<template>
    <b-container>
        <b-row class="mx-0">
            <b-col cols="12" class="home">
                <h1 class="mb-5 mt-5">{{welcome}}</h1>
                <contentblock v-for="(content, contentIndex) in contentBlocks"
                    :key="contentIndex"
                    :text="content.text"
                    :imageUrl="content.imageUrl"
                    :blockStyle=1 />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Contentblock from"../Contentblock.vue"

export default {
    components: {
        Contentblock
    },
    data() {
        return {
            welcome: "Welkom!",
            contentBlocks: [
                {
                    text: "NewCreative.nl is nog in aanbouw. Binnenkort zal er hier meer informatie te vinden zijn. Voor contact en informatie kunt u mij bereiken per mail op <a href=\"mailto:info@newcreative.nl\">info@newcreative.nl</a> ."
                },
                {
                    text: "NewCreative is de koepelterm die ik gebruik voor mijn kunst en mijn creatieve dienstverlening. Van digitale sculpuren, schilderijen, muziek en meer - ik help graag met het realiseren van ideeën. Dus zit je vast in je creatieve proces of ben je op zoek naar het ontbrekende puzzelstukje? Neem dan contact op.",
                    imageUrl: ""
                }
                /* {
                    text: "Naast het aanbieden van creatieve diensten is dit ook eeen hub voor kunst en cultuur - ik geloof dat een goede kennis van klassieke kunstvormen een goede basis vormt voor moderne media zoals digitaal werk. Tevens plaats ik hier ook artikelen en interviews met andere creatievelingen, kunstenaars en bohemen, om bij te dragen aan het verspreiden van prikkelende ideeën.",
                    imageUrl: "https://video.cgtn.com/news/2021-05-02/Wolong-National-Nature-Reserve-extracts-DNA-from-panda-droppings--ZVKNEVWE2A/video/5a8c73f8277a46b29cd14c5f1cdac0b9/5a8c73f8277a46b29cd14c5f1cdac0b9.jpg"
                },
                {
                    text: "Het voornaamste gereedschap dat ik gebruik is uitgebreide kennis van 3D software. De modellen die ik lever zijn geschikt voor o.a. 3D printen, visualisaties en videogames. Verder kan je bij mij terecht voor beeld- en muziekgerelateerde problemen.",
                    imageUrl: ""
                }, */
            ]
        }
    }
}
</script>

<style>

</style>