<template>
    <b-row class="contentblock">
        <b-col>
            <h5
            v-html="text"></h5>
        </b-col>
        <b-col v-if="this.imageUrl" cols="6">
            <b-img :src="imageUrl" fluid></b-img>
        </b-col>
    </b-row>
</template>

<script>
export default {
    props: {
        text: String,
        imageUrl: String,
        blockStyle: Number
    }
}
</script>

<style>

</style>